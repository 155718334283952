@import "all";

.Desktop {

  .mt-6 {
    margin-top: $spacer-gap !important;
  }

  @include media-breakpoint-down(sm) {
    display: none !important;

  }
}
