@import "all";

.ContactCard {
  .body-text-contact a,
  .body-text-contact a:hover {
    color: $base-color;
    text-decoration: underline;
  }

  .contact-container {
    padding: 0px 33px !important;
    font-size: 12px !important;

    font-family: $font-body;
  }
  .contact-img-container {
    margin-bottom: 20px;
  }
  .contact-img {
    width: 83pt;
    height: 83pt;
    position: absolute;
    bottom: 25px;
    right: 0;
  }
  .body-text-contact {
    margin-top: -5.9px;
    margin-bottom: 18.3px;
    font-family: $font-body;
  }
  .body-text-contact p {
    color: $body-text-color;
    font-size: 16pt;
    margin: 0 !important;
    letter-spacing: 0px;
    line-height: 24.8px;
  }
  .body-text-contact-heading {
    font-weight: bold;
    font-size: 18pt;
    letter-spacing: -0.3px;
    line-height: 15px;
    color: $contact-heading;
  }

  @include media-breakpoint-down(sm) {
    .contact-img {
      right: -24px;
      bottom: 24px;
      width: 63pt;
      height: 63pt;
    }
    .contact-container {
      padding: 0px 21px !important;
    }
    .body-text-contact-heading {
      font-weight: bold;
      font-size: 13.9px;
      letter-spacing: 0px;
      line-height: 14.3px;
      margin-bottom: 18px;
    }
    .body-text-contact {
      margin-top: -15px;
    }
    .body-text-contact p {
      color: $body-text-color;
      font-size: 13.9px;
      margin: 0 !important;
      letter-spacing: -0.4px;
      line-height: 16px;
    }
    .btn-maroon {
      padding: 5.3px 9px;
    }
    .body-text-copyright {
      font-size: 10.8px;
      line-height: 9.8px;
      padding: 0 20.5px 26.4px;
      letter-spacing: -0.5px;
    }
  }
}
