@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/_mixins';

$base-color:#385593;
$body-text-color: #222222;
$contact-heading:#222222;
$copyright-color:#A0A0A0;
$inactive-link-color:#A0A0A0;

$font-body:'PT Sans';
$font-heading: 'indira_kregular';

$spacer-gap: 13.8rem;
